@import '@dangl/angular-material-shared/styles/material-style';
@import '@dangl/angular-material-shared/styles/material-variables';
@import './style-fix-material.scss';

html,
body,
.app-content {
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

body {
  margin: 0;
}

.snackbar-success {
  background-color: $color-primary !important;
}

.snackbar-error {
  background-color: $color-warn !important;
}

.text-addition .text-addition-body {
  background-color: yellow;
}

.modal-change-contact {
  height: 400px;
  width: 500px;
}

.buttons-global {
  padding-top: 10px;

  > * {
    &:nth-child(n) {
      margin-bottom: 10px;
    }

    &:nth-child(n + 2) {
      margin-left: 12px;
    }
  }
}

input:focus-visible,
textarea:focus-visible,
select:focus-visible {
  outline-color: $color-primary !important;
}

table {
  width: 100%;
  .actions {
    width: 130px;
  }
}

.custom-caret {
  caret-color: transparent;
}

.separator {
  position: absolute;
  bottom: 0;
  left: 2px;
  display: block;
  z-index: 102;
  background-color: $color-primary;
  color: $color-primary;
  animation: blinker 1.1s infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
