@use '@angular/material' as mat;
@include mat.typography-hierarchy(mat.m2-define-typography-config());

.mat-primary:not(.mdc-icon-button) {
  .mdc-button__label,
  > .mat-icon,
  &.mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark {
    color: #fff;
  }
}

.mat-mdc-form-field:not([appearance]) .mat-mdc-text-field-wrapper {
  background: transparent;
}

div.mdc-form-field {
  .mdc-checkbox {
    padding: 0;
    flex: 0 0 16px;
    width: 16px;
    height: 16px;

    .mdc-checkbox__native-control {
      width: 100%;
      height: 100%;

      &:focus ~ .mdc-checkbox__ripple {
        opacity: 0 !important;
      }
    }

    .mdc-checkbox__background {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  > label {
    line-height: 24px;
    padding-left: 8px;
  }
}
